import { Injectable } from '@angular/core';

import { SpsStorageService } from '@services/storage/sps-storage.service';

import { LOCALSTORAGE_SPS_USER_KEY, SPS_USER_CREDENTIALS, USER_SESSION_EXPIRY_TIME } from '@core/config';

@Injectable({ providedIn: 'root' })
export class UserService {
  static get isLoggedIn(): boolean {
    const entry = SpsStorageService.getEntry<{ username: string; expires: number }>(LOCALSTORAGE_SPS_USER_KEY);

    if (!entry) {
      return false;
    }

    return Date.now() < entry.expires;
  }

  public authenticate(credentials: Partial<{ username: string; password: string }>): boolean {
    const { username, password } = SPS_USER_CREDENTIALS;

    if (credentials.username === username && credentials.password === password) {
      this.login(credentials.username);
      return true;
    }

    return false;
  }

  private login(username: string): void {
    const expires = Date.now() + USER_SESSION_EXPIRY_TIME;
    SpsStorageService.saveEntry(LOCALSTORAGE_SPS_USER_KEY, { username, expires });
  }
}
